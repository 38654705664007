<template>
  <div>

    <body>
      <div class="container-scroller">
        <!-- partial:partials/_navbar.html -->
        <Topnav @toggleChat="toggleChat"/>
        <!-- partial -->
        <div class="container-fluid page-body-wrapper">
          <!-- Sidebar -->
          <Sidebar />
          <!-- partial -->
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="main-chat-content">
                <router-view class="main-content"></router-view>
                <div class="chat-content chat-container" id="chat-container" v-if="showChat">
                  <div class="chat-header">
                    <div class="chat-img-text">
                      <img
                          src="../../public/images/logo1.png"
                          class="me-2 chat-header-avatar"
                          alt="logo"
                          style="width: 80%"
                         />
                      <div class="chat-header-title">
                        App Design Support
                      </div>
                    </div>
                  </div>
                  <div class="chat-messages" id="chat-messages">

                  </div>
                  <div class="chat-footer">
                    <input type="text" class="message-input" id="message-input" placeholder="Ask me anything or type a message" />
                    <button class="send-button">
                      <i class="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- main-panel ends -->
        </div>
        <!-- page-body-wrapper ends -->
      </div>
      <!-- container-scroller -->
    </body>
  </div>
</template>
<script>
import Topnav from '../components/topnav.vue'
import Sidebar from '../components/sidebar.vue'
import baseUrl from '@/shared/baseUrl';
import { useToast } from "vue-toastification";

export default {
  name: 'CreateApp',
  components: {
    Topnav,
    Sidebar
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast }
  },
  data() {
    return {
      createAppData: {
        appName: '',
        description: '',
        useTemplateTag: '',
        shortCode: '',
      },
      msg: '',
      showChat: false
    }
  },
  methods: {
    createApp() {
      // const token = localStorage.getItem('access_token')
      //check for empty fields
      if (this.createAppData.appName == '' || this.createAppData.description == '' || this.createAppData.useTemplateTag == '' || this.createAppData.shortCode == '') {
        this.msg = 'Please fill all the fields'
      }
      else {
        baseUrl.post('/createApp', this.createAppData)
          .then(response => {
            this.toast.success(response.data.message);
            this.createAppData.appName = ''
            this.createAppData.description = ''
            this.createAppData.useTemplateTag = ''
            this.createAppData.shortCode = ''
          }).catch(error => {
            this.toast.error(error.message);
          })
      }

    },
    toggleChat() {
      this.showChat = !this.showChat
    }
  },
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push('/login')
    }
  }
}
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;

}
#error {
  margin-top: 40px;
}
.main-chat-content{
  display: flex;
}
.main-content{
  flex-grow: 1;
  width: 80%;
}
.chat-container {
  position: fixed;
  bottom: 90px; /* Adjust as needed */
  right: 20px;
  z-index: 1000;
  max-width: 400px;
  width: 100%;
  height: 600px;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  background-color: #e9e9e9;
}
.chat-header {
  background-color: #1E1E2F;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-header-title {
  font-size: 18px;
  margin-top: 10px;
}

.chat-header-avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 10px;
}

.chat-img-text {
  text-align: center;
}
.chat-footer {
  position: absolute;
  border-top: 1px solid #f7f7f9;
  z-index: 3;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-footer input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  background-color: #fff;
}
.send-button {
  background-color: transparent;
  color: #1E1E2F;
  font-size: 20px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}
.chat-messages {
  height: 500px;
  overflow-y: scroll;
  padding: 20px;
}
</style>

